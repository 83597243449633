<style lang="scss" scoped>
.ui-left {
  width: 360px;
  position: absolute;
  top: 109px;
  left: 20px;
  z-index: 4;
  img {
    width: 100%;
  }
}
.ui-right {
  width: 360px;
  position: absolute;
  top: 109px;
  right: 20px;
  z-index: 4;
  img {
    width: 100%;
  }
}
</style>

<template>
  <div class="index">
    <div class="ui-left" v-rc:left>
      <div class="button button1"></div>
      <div class="button button2"></div>
      <div class="button button3"></div>
      <img src="./ui-left.png" alt="" />
    </div>
    <div class="ui-right" v-rc:right>
      <img src="./ui-right.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>
